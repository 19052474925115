import React from "react"
import { Link } from "gatsby"

import styles from "./co-create-contact.module.scss"
import theme from "../styles/theme.module.scss"

function CoCreateContact({ title, columns, rootClass = '', wrapperClass = '', titleClass = '', columnClass = '' }) {
  return (
    <div className={`${styles.root} ${rootClass}`}>
      <div id={`create`} className={`${styles.wrapper} ${wrapperClass}`}>
        <h2 className={`${styles.title} ${theme.gds1} ${theme.gde11} ${titleClass}`}>
          {title}
        </h2>

        <div className={`${styles.columns}`}>
          <div
            className={`${styles.column} ${theme.gds1} ${theme.gde4} ${columnClass}`}
          >
            <p className={`${styles.body}`}>{columns[0].columnCopy}</p>
            <Link
              className={`${styles.link} ${theme.text_link}`}
              to={columns[0].columnCtaLink}
            >
              {columns[0].columnCta}
            </Link>
          </div>
          <div
            className={`${styles.column} ${theme.gds5} ${theme.gde8} ${columnClass}`}
          >
            <p className={`${styles.body}`}>{columns[1].columnCopy}</p>
            <Link
              className={`${styles.link} ${theme.text_link}`}
              to={columns[1].columnCtaLink}
            >
              {columns[1].columnCta}
            </Link>
          </div>
          <div
            className={`${styles.column} ${theme.gds9} ${theme.gde12} ${columnClass}`}
          >
            <p className={`${styles.body}`}>{columns[2].columnCopy}</p>
            <Link
              className={`${styles.link} ${theme.text_link}`}
              to={columns[2].columnCtaLink}
            >
              {columns[2].columnCta}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoCreateContact
